<template>
  <div>
    <tags-autocomplete
      class="mb-1"
      with-search
      :tags="tags"
      @selected="tagsUpdated"
      @searchUpdated="(query) => searchUpdated(query)"
    />
    <div class="mb-1 d-flex align-items-center">
      <b-form-checkbox-group
        v-model="typesQuery"
        @change="getSteps"
      >
        <b-form-checkbox
          v-for="type in stepTypes"
          :key="`step-${type.value}`"
          :value="type.value"
        >
          {{ type.title }}
        </b-form-checkbox>
      </b-form-checkbox-group>
      <div class="flex-fill" />
      <b-button
        v-if="selectable && steps.length"
        variant="flat-primary"
        size="sm"
        @click="selectAll"
      >
        <feather-icon
          :icon="areAllSelected ? 'XIcon' : 'CheckIcon'"
          class="mr-50"
        />
        {{ areAllSelected ? 'Отменить все' : 'Выбрать все' }}
      </b-button>
      <b-button
        v-if="selectable && hasSelected"
        variant="primary"
        size="sm"
        class="ml-1"
        @click="sendSelectedSteps"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        Добавить к уроку
      </b-button>
    </div>
    <template v-if="stepToPreview">
      <step-editor
        :step="stepToPreview"
        is-preview
        selectable
        :selected="stepIsSelected(stepToPreview)"
        @onClosePreview="() => stepToPreview = null"
        @onSelect="() => selectStep(stepToPreview)"
      />
    </template>
    <template v-else-if="loading">
      <skeletor
        v-for="(skeleton, key) in 4"
        :key="key"
        width="100%"
        height="150"
        class="mb-1"
      />
    </template>
    <template v-else>
      <step-item
        v-for="step in steps"
        :key="step.id"
        :step="step"
        :selectable="selectable"
        :selected="stepIsSelected(step)"
        :with-preview="withPreview"
        @onSelect="selectStep"
        @deleted="getSteps"
        @subjectSelected="(subj) => $emit('subjectSelected', subj)"
        @gradeSelected="(grd) => $emit('gradeSelected', grd)"
        @onPreview="() => previewStep(step)"
      />
      <div class="d-flex justify-content-between">
        <span class="font-medium-2 text-body">
          <span class="text-muted">Всего карточек: {{ meta.totalCount }} </span>
        </span>

        <list-pagination
          v-if="meta.totalCount / meta.perPage > 1"
          :page="meta.currentPage"
          :limit="meta.perPage"
          :total-number="meta.totalCount"
          :pagination-updated="pageChanged"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { BFormCheckboxGroup, BFormCheckbox, BButton } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ListPagination from '@/components/courses/course/ListPagination.vue'
import StepItem from '@/components/steps/StepItem.vue'
import TagsAutocomplete from '@/components/page-elements/filters/TagsAutocomplete.vue'
import StepEditor from '../new_courses/course/theme/lesson/step/StepEditor.vue'
import { saveFilters } from '../../helpers'

export default {
  name: 'StepsList',
  components: {
    StepEditor,
    TagsAutocomplete,
    StepItem,
    ListPagination,
    BFormCheckboxGroup,
    BFormCheckbox,
    BButton,
  },
  props: {
    filter: {
      type: Object,
      default: () => ({
        types: [],
        subjects: [],
        grades: [],
        tags: [],
        courseId: null,
        lessonId: null,
        favorite: false,
        filterType: null,
      }),
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    withPreview: {
      type: Boolean,
      default: false,
    },
    selectedSteps: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    loading: false,
    searchQuery: '',
    typesQuery: [],
    meta: {
      currentPage: 1,
      pageCount: 0,
      perPage: 15,
      totalCount: 0,
    },
    steps: [],
    tags: [],
    stepsToSelect: [],
    stepToPreview: null,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    hasSelected() {
      return !!this.stepsToSelect.filter(step => !step.toDelete).length
    },
    areAllSelected() {
      return this.stepsToSelect.filter(step => !step.toDelete).length === this.steps.length
    },
    stepTypes() {
      return [
        {
          value: 0,
          title: 'Лекции',
        },
        {
          value: 1,
          title: 'Задания',
        },
      ]
    },
  },
  watch: {
    tags(value) {
      this.$emit('tagsUpdated', value)
    },
  },
  mounted() {
    this.getSteps()
    this.stepsToSelect = [...this.selectedSteps]
  },
  beforeMount() {
    const { page, limit } = this.filter
    if (this.filter?.page) this.meta.currentPage = page
    if (this.filter?.limit) this.meta.perPage = limit
  },
  methods: {
    load() {
      this.getSteps()
    },
    tagsUpdated(tags) {
      this.tags = [...tags]
    },
    pageChanged({ page, limit }) {
      if (page) this.meta.currentPage = page
      if (limit) this.meta.perPage = limit
      saveFilters(this.$router, { ...this.filter, page, limit })
      this.getSteps()
    },
    async getSteps() {
      const {
        $http, filter, meta, searchQuery, typesQuery,
      } = this

      this.loading = true

      const { items, _meta } = await $http.get('/lesson-steps', {
        params: {
          filter_type: filter.filterType,
          name: searchQuery,
          subject_id: filter.subjects.join(','),
          grade_id: filter.grades.join(','),
          coursesId: filter.courseId,
          lessonsId: filter.lessonId,
          type: typesQuery.join(','),
          tagsId: filter.tags.join(','),
          limit: meta.perPage,
          page: meta.currentPage,
          expand: 'user,stepElements.image,subject,grade,lessonStepsTags.tags',
        },
      })
      this.meta.pageCount = _meta.pageCount
      this.meta.totalCount = _meta.totalCount
      this.steps = items
      this.loading = false
    },
    searchUpdated(query) {
      this.searchQuery = query
      this.getSteps()
    },
    sendSelectedSteps() {
      this.stepsToSelect.forEach(step => {
        if (!this.stepIsSelected(step)) this.$emit('onSelect', step)
      })
    },
    selectStep(selectedStep, onlyAdd = false) {
      if (!this.stepIsSelected(selectedStep)) {
        if (this.stepsToSelect.find(step => step.step_id === selectedStep.id)) {
          this.stepsToSelect = this.stepsToSelect.map(step => {
            const st = { ...step }
            if (st.step_id === selectedStep.id) st.toDelete = false
            return st
          })
        } else {
          this.stepsToSelect.push({
            step_id: selectedStep.id,
            step: selectedStep,
            toDelete: false,
          })
        }
      } else if (!onlyAdd) {
        this.stepsToSelect = this.stepsToSelect.map(step => {
          const st = { ...step }
          if (st.step_id === selectedStep.id) st.toDelete = true
          return st
        })
      }
    },
    selectAll() {
      const { areAllSelected } = this
      this.steps.forEach(step => {
        this.selectStep(step, !areAllSelected)
      })
    },
    stepIsSelected(step) {
      return !!this.stepsToSelect.find(st => st.step_id === step.id && !st.toDelete)
    },

    previewStep(step) {
      this.stepToPreview = step
    },
  },
}
</script>

<style scoped>

</style>
